<template>
  <v-layout wrap row justify-center align-center class="mb-0">
    <v-flex>
      <h1 class="mb-0 display-1 font-weight-light" v-if="this.title!=='' && this.title !== undefined" v-html="this.title"></h1>
      <h1 class="mb-0 display-1 font-weight-light" v-else><slot name="title"></slot></h1>
      <h2 class="subheading font-weight-light mb-0" style="padding-left: 2px;" v-if="this.subtitle !== '' && this.subtitle !== undefined" v-html="this.subtitle"></h2>
      <h2 class="subheading font-weight-light mb-0" style="padding-left: 2px;" v-else>
        <slot name="subtitle"></slot>
      </h2>
    </v-flex>
    <v-flex class="text-xs-right">
      <slot name="button"></slot>
    </v-flex>
    <v-flex xs12>
      <v-divider style="opacity:0.3"></v-divider>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'Title',
  props: ['title', 'subtitle'],
};
</script>

<style>
  h1 {
    color: #3C4858;
  }
</style>
